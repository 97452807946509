<template>
  <div id="sharelist">
    <div class="tfh">
      <a href="javascript:history.back(-1);">
        <img src="../assets/img/jt1.png" alt="" />
      </a>
      分享商品
    </div>
    <div class="top">
      <div class="searchbox">
        <div class="selectbox" @click="selectbox()">{{ selectname }}</div>
        <div class="searcenter" style="width: 70%">
          <span class="icon"></span>
          <input
            id="search"
            type="text"
            placeholder="搜索商品、手机号、分享人"
            @input="search"
          />
        </div>
        <div class="rightbox">
          <a class="searchbtn" @click="searchbtn">搜索</a>
        </div>
      </div>
    </div>
    <div class="outer">
      <ul id="list">
        <li class="listli" v-for="(item, index) in list" :key="index">
          <div class="d1">
            <p class="pt sheng2">{{ item.goods_name }}</p>
            <p class="p1 p2">
              <span class="sheng">分享人：{{ item.from.nickname }}</span
              ><span class="s2 sheng">接收人：{{ item.to.nickname }}</span>
            </p>
            <p class="p2">分享时间：{{ item.created_at }}</p>
          </div>
        </li>
      </ul>

      <ul class="pagination">
        <li @click="goPage(page == 1 ? 1 : page - 1)">上一页</li>
        <li
          v-for="(item, index) in allpage"
          :key="index"
          @click="goPage(index + 1)"
          :style="
            page == index + 1
              ? 'background: #fee405;border-color: #fee405;color:#fff'
              : ''
          "
        >
          {{ index + 1 }}
        </li>
        <li @click="goPage(page == allpage ? allpage : page + 1)">下一页</li>
      </ul>
    </div>
    <div class="loading" v-if="isload">
      <img class="aaaaa" src="../assets/img/loadding.gif" alt="" />
    </div>
    <div class="mask">
      <div class="masker" @click="maskhide()">
        <ul>
          <label>
            <li
              @click="iptradio('商品名')"
              style="border-bottom: 1px solid rgba(50, 50, 51, 0.1)"
            >
              <p class="left">商品名</p>
              <input
                class="iptchecked"
                type="radio"
                value="0"
                name="1"
                checked="checked"
              />
              <img
                class="choiceimg1"
                src="../assets/img/choice.png"
                alt=""
                v-if="selectname == '商品名'"
              />
            </li>
          </label>
          <label>
            <li @click="iptradio('分享人')">
              <p class="left">分享人</p>
              <input class="iptchecked" type="radio" value="1" name="1" />
              <img
                class="choiceimg2"
                src="../assets/img/choice.png"
                alt=""
                v-if="selectname == '分享人'"
              />
            </li>
          </label>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isload: false,
      list: [],
      page: 1,
      allpage: 1,
      goodsname: "",
      selectname: "商品名",
      url: "",
    };
  },
  created() {
    this.url = `/store/share_goods_user_list?page=${this.page}&goodsname=${this.goodsname}`;
    this.share_goods_user_list();
  },
  methods: {
    selectbox() {
      $(".mask").show();
    },
    maskhide() {
      $(".mask").hide();
    },
    iptradio(name) {
      this.selectname = name;
    },
    search(e) {
      var _this = this;
      _this.goodsname = e.target.value;
    },

    searchbtn(e) {
      var _this = this;
      _this.page = 1;
      console.log(_this.selectname);
      if (_this.selectname == "商品名") {
        this.url = `/store/share_goods_user_list?page=${_this.page}&goodsname=${_this.goodsname}`;
      } else {
        this.url = `/store/share_goods_user_list?page=${_this.page}&search=${_this.goodsname}`;
      }
      _this.share_goods_user_list();
    },

    share_goods_user_list() {
      var _this = this;
      _this.$http
        .get(_this.STORE + _this.url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(
          (res) => {
            console.log(res.data);
            if (_this.page == 1 && res.data.data.list.length == 0) {
              elastic("没有记录");
            } else {
              _this.list = res.data.data.list;
            }
          },
          (res) => {
            console.log("调用失败");
          }
        );
    },
  },
};
</script>

<style>
@import "../assets/css/sharelist.css";
@import "../assets/css/common.css";
</style>