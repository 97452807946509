<template>
  <div id="sharelist">
    <div class="tfh">
      <a href="javascript:history.back(-1);">
        <img src="../assets/img/jt1.png" alt="" />
      </a>
      分享记录
    </div>
    <div class="top">
      <div class="searchbox">
        <div class="searcenter">
          <span class="icon"></span>
          <input
            id="search"
            type="text"
            placeholder="分享人手机号或昵称"
            @input="sharesearch"
          />
        </div>
        <div class="rightbox">
          <a class="searchbtn" @click="searchbtn">搜索</a>
        </div>
      </div>
    </div>
    <div class="outer">
      <ul id="list">
        <li class="listli" v-for="(item, index) in list" :key="index">
          <div class="d1">
            <p class="p1">
              <span class="sheng">分享人：{{ item.from.nickname }}</span
              ><span class="s2 sheng">接收人：{{ item.to.nickname }}</span>
            </p>
            <p class="p2">分享时间：{{ item.created_at }}</p>
          </div>
        </li>
        <ul class="pagination">
          <li @click="goPage(page == 1 ? 1 : page - 1)">上一页</li>
          <li
            v-for="(item, index) in allpage"
            :key="index"
            @click="goPage(index + 1)"
            :style="
              page == index + 1
                ? 'background: #fee405;border-color: #fee405;color:#fff'
                : ''
            "
          >
            {{ index + 1 }}
          </li>
          <li @click="goPage(page == allpage ? allpage : page + 1)">下一页</li>
        </ul>
      </ul>
      <!-- <div id="myPage" class="demo"></div> -->
    </div>
    <div class="loading">
      <div>
        <img class="aaaaa" src="/seller/images/my/loadding.gif" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      allpage: 1,
      search: "",
      pagesize: 10,
      template: ``,
    };
  },
  created() {
    this.share_store_user_list();
  },
  methods: {
    msgListView(curPage) {
      //根据当前页获取数据
      this.page = curPage;
      this.curPage = curPage;
    },
    sharesearch(e) {
      var _this = this;
      _this.search = e.target.value;
    },

    searchbtn(e) {
      var _this = this;
      _this.page = 1;
      _this.share_store_user_list();
    },

    share_store_user_list() {
      var _this = this;
      console.log(_this.search);
      _this.$http
        .get(
          _this.STORE +
            `/store/share_store_user_list?page=${_this.page}&search=${_this.search}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(
          (res) => {
            console.log(res.data);
            if (_this.page == 1 && res.data.data.list.length == 0) {
              elastic("没有记录");
            } else {
              _this.list = res.data.data.list;
            }
            if (_this.page == 1) {
              _this.showPageBtn();
            }
          },
          (res) => {
            console.log("调用失败");
          }
        );
    },

    showPageBtn() {
      this.allpage = parseInt(10 / this.list.length);
    },

    goPage(page) {
      console.log(page);
      if (page != this.curPage) {
        console.log(page);
        var that = this;
        that.page = page;
        that.share_store_user_list();
      } else {
        console.log("Already in the current page");
      }
    },
  },
};
</script>

<style>
@import "../assets/css/sharelist.css";
@import "../assets/css/common.css";
</style>