<template>
  <div id="ruzhu">
    <div class="content">
      <div class="tfh">
        <a @click="returns()"><img src="../assets/img/jt1.png" alt="" /></a>
        商家入驻
      </div>
      <img src="../assets/img/rz1.png" class="top" alt="" />
      <img src="../assets/img/rz2.png" alt="" class="top2" />
      <div class="biaot">商家入驻部分规则（需接受或满足以下全部条件）</div>
      <ul class="outer">
        <li>1、要求入驻商家为符合国家法律法规且资质齐全的企业</li>
        <li>2、需要缴纳保证金，退店后退质保金</li>
        <li>
          3、商家需要接受以下服务条款：<br />
          ① 售卖假冒伪劣或三无产品，将冻结贷款、扣除<br />保证金、并清退<br />
          ② 超出承诺时效发货或所售商品质量存在超标顾<br />
          客投诉，将有罚款
          <br />③ 商家需能保证每天10:00-21:00的在线回复客户咨询及处<br />
          理售后
        </li>
      </ul>
      <div style="text-align: center">
        <button
          style="
            background: #fee400;
            height: 90px;
            width: 668px;
            font-size: 29px;
            font-weight: 550;
            letter-spacing: 5px;
            margin-bottom: 70px;
          "
          @click="appt()"
        >
          前往入驻
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    returns() {
      finish(); //返回上一层
    },
    appt() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style>
@import "../assets/css/ruzhu.css";
@import "../assets/css/common.css";
</style>