import Vue from 'vue'
import VueRouter from 'vue-router'
import ruzhu from '../views/ruzhu.vue'
import problem from '../views/problem.vue'
import content from '../views/content.vue'
import wenzhang from '../views/wenzhang.vue'
import guideBook from '../views/guideBook.vue'
import share from '../views/share.vue'
import goodssharelist from '../views/goodssharelist.vue'
import storesharelist from '../views/storesharelist.vue'
import home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'home',
  component: home
}, {
  path: '/ruzhu',
  name: 'ruzhu',
  component: ruzhu
}, {
  path: '/problem',
  name: 'problem',
  component: problem
}, {
  path: '/content',
  name: 'content',
  component: content
}, {
  path: '/guideBook',
  name: 'guideBook',
  component: guideBook
}, {
  path: '/share',
  name: 'share',
  component: share
}, {
  path: '/wenzhang',
  name: 'wenzhang',
  component: wenzhang
}, {
  path: '/goodssharelist',
  name: 'goodssharelist',
  component: goodssharelist
}, {
  path: '/storesharelist',
  name: 'storesharelist',
  component: storesharelist
}]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router