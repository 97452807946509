<template>
  <!-- 隐私政策/用户协议 -->
  <div id="content">
    <div class="all" style="font-size:30px">
      <div class="tfh" v-if="article_id != 15&&article_id != 23">
        <a @click="returns()"><img src="../assets/img/jt1.png" alt="" /></a>
        {{ cat_name }}
      </div>
      <div class="content" v-if="titletype != 1" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cat_name: "",
      content: "",
      titletype: 0,
      article_id:''
    };
  },
  created() {
    var that = this;
    var article_id = that.$route.query.article_id;
    that.article_id = article_id
    console.log(article_id);
    if (article_id == 14) {
      that.cat_name = "用户协议";
    } else if (article_id == 22) {
      that.cat_name = "隐私政策";
    } else if (article_id == 15) {
    } else if (article_id == 23) {
    } else {
      that.cat_name = "常见问题";
    }
    that.get_article_content();
  },
  methods: {
    returns() {
      finish(); //返回上一层
    },
    get_article_content() {
      var that = this;
      var article_id = that.$route.query.article_id;
      that.$http
        .get(that.USER + `/user/get_article_content?article_id=${article_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(
          (res) => {
            console.log(res);
            that.content = res.data.data.content;
          },
          (res) => {
            console.log("调用失败");
            elastic(res.data.msg);
          }
        );
    },
  },
};
</script>

<style>
@import "../assets/css/content.css";
@import "../assets/css/common.css";
</style>