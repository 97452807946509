<template>
  <div id="guideBook">
    <div class="tfh">
      <a @click="returns()"><img src="../assets/img/jt1.png" alt="" /></a>
      使用手册
    </div>
    <div class="scmain">
      <div id="topNav" class="swiper-container">
        <div
          class="swiper-wrapper"
          @click="onactive(index)"
          v-for="(item, index) in active"
          :key="index"
        >
          <div class="swiper-slide" :class="isactive == index ? 'active' : ''">
            <span>{{ item.test }}</span>
          </div>
        </div>
      </div>
      <div class="swiper-container" id="swiper2">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-if="isactive == 0">
            <div class="scbox">
              <h3>个人店铺</h3>
              <p>
                适用于个人，提供身份证即可开店；开通后需要上传至少2件商品才可以进行正常营业；个人店铺的商品无法出现在公域平台
              </p>
              <h3>个体户</h3>
              <p>
                适用于个体户商家，提供身份证、营业执照即可开店；开通后需要上传至少4件商品才可以进行正常营业
              </p>
              <h3>企业店铺</h3>
              <p>
                适用于企业开店，提供身份证、营业执照等相关资料；开通后需要上传至少6件商品才可以进行正常营业
              </p>
              <h3>店铺会员</h3>
              <p>
                商家推荐贯鱼新用户后，该用户与自己店铺会产生绑定关系，成为店铺会员，此类会员在贯鱼商城其他商家处成功消费后会给自己带来被动收益；其他商家推荐的贯鱼用户浏览关注了自己的店铺，也成为店铺会员，此类会员不会给自己带来被动收益
              </p>
              <h3>被动收益</h3>
              <p>
                A商家的店铺商品被B商家的绑定会员购买后，A商家需将此笔交易额的一部分费用奖励给B商家作为被动佣金；所有商家均遵循此规则，被动收益设置原则：订单交易额的3%+单笔商品最高30元封顶
              </p>
            </div>
          </div>
          <div class="swiper-slide" v-if="isactive == 1">
            <div class="scbox">
              <h3>食品（认证）、酒类、医疗器械、出版书物资质</h3>
              <p>
                部分主营类目在实际经营前，需要额外上传行业资质/证书，且保证金也会有不同要求。
                如需上传资质，请点击【贯鱼商家端APP】进入【我的】-【经营许可证】，即可上传资质证明。
              </p>
              <p>特殊行业资质要求:</p>
              <p>
                食品：食品行业需要提交食品经营许可证（或食品流通许可证），方可发布商品。若经营酒类且提供的食品经营许可证中经营范围不包含酒类时，需要额外提供酒类流通备案登记表（或声明书）。
              </p>
              <p>出版物资质：企业店铺上传营业执照+《出版物经营许可证》即可。</p>
              <p>
                水果生鲜：发布水果类目商品需要缴纳8000元特殊店铺保证金，大闸蟹资质要求提货系统、进货凭证。
                数码电器：全国工业产品生产许可证、3C证书。
                虚拟商品：运营商授权资质书、商务局预付卡备案。
                海淘进口：报关单。
              </p>
            </div>
          </div>
          <div class="swiper-slide" v-if="isactive == 2">
            <div class="scbox">
              <h3>0元保证金也能正常售卖</h3>
              <p>
                为了降低经营门槛，不缴纳保证金也可以正常上传商品并正常售卖。
              </p>
              <p>
                目前0元入驻店铺支持的功能是先发布商品，后缴纳保证金。（暂不适用于一般贸易进口商品作为经营方式入驻的店铺）
              </p>
              <p>
                无法新建推广活动;<br />
                限制发布虚拟商品及其他需要额外保证金的商品；<br />
                店铺可用资金＜300元，订单总额不能超过2500元；<br />
                订单数不能超过1000单；<br />
                提现不能超过3次；<br />
                店铺违规次数不能超过3次；<br />
                店铺违规订单不能超过10单；
              </p>
              <h3>店铺保证金</h3>
              <p>
                指商家向平台缴存的，用来保证其提供优质商品和服务，以及严格按照《贯鱼平台合作协议》及各项平台规定合格经营的资金。
              </p>
              <p>
                缴纳金额：数码电器/电脑办公类、家居建材/家居厨卫类、黄金珠宝类、二手商品类、装饰/设计类、话费通讯类保证金都是5000元；其他各品类保证金均为2000元；
              </p>
              <p>
                店铺保证金赔付:保证金在店铺出现违约情形时用于向用户进行赔付、赔偿平台的损失或承担其他违约责任；
              </p>
              <p>
                保证金补缴：保证金被扣划赔付后，需要补缴被扣划部分；商家在未缴付的情况下货款金额不允许提现；
                工具箱钱包：
              </p>
              <p>
                工具箱钱包与店铺保证金的区别：工具箱钱包用于参加平台活动或者活动完成后的退款，店铺保证金用于日常合规经营。
              </p>
              <p>
                佣金：佣金是指通过本店铺注册的会员到任何其他店铺购物，每笔订单都会有不同的佣金，佣金可以通过提现至货款，通过货款提现至个人用户；
              </p>
            </div>
          </div>
          <div class="swiper-slide" v-if="isactive == 3">
            <div class="scbox">
              <h3>商品发布</h3>
              <p>
                商品发布分简单发布与详细发布，快捷发布只需填写商品标题、上传商品图、商品短标题、商品分类、商品单价、商品库存、市场价就可直接售卖商品，简单发布系统默认提货方式为快递，运费模板默认非偏远包邮默认模板，发货时间默认48小时内，库存计件默认支付成功减库存，根据店铺分类默认是否支持七天无理由退款；详细发布需要填写商品标题，上传商品图、商品短标题、商品分类、市场价、商品详情、上传商品详情图、商品多规格添加、本店分类、品牌选择、是否支持会员折扣、是否为虚拟商品、提货方式选择、可自行设置运费模板、自提时间设置、库存计件方式、是否支持七天无理由退款
              </p>
              <h3>爆款商品</h3>
              <p>
                每位商家都可以设置最多两个价格比较低的商品，作为爆款商品展示在【贯鱼用户端】首页页面，以此曝光店铺活跃度，让消费者更容易找到你（规则：此页面商品为商家设置的爆款商品，每天的0:00-2:00、2:01-4:00、4:01-6:00、6:01-8:00、8：01-10:00、10:01-12:00、12:01-14:00、14:01-16:00、16:01-18:00、18:01-20:00、20:01-22:00、22:01-24:00都会有不同品类的爆款商品更新，如果有您相中的商品可以点击进行购买哦~）
              </p>
              <h3>订单</h3>
              <p>
                待付款的订单在24小时以后自动关闭，在此期间可对商品进行改价或者提醒来提示消费者对此订单付款
                ；
                待发货的订单在48小时还未发货，平台将自动取消订单并退款给消费者，以此确保消费者的合法权益；
                待收货的商品如消费者已经收到货但是未及时按确认收货，平台自动判定商品寄出后10天内自动确认收货；
              </p>
              <p>
                线下核销的订单，在用户到店后商家扫描核销码进行确认收货，第二天核算商家货款；
              </p>
              <p>
                一般订单在用户确认收货后7天后核算商家货款；
                退款商品分两种情况：第一种是商家未发货这种情况下商家只能同意退款；第二种是商家已发货商家有拒绝退货的权利，如果消费者将订单交给平台处理，那平台就是最终决定者，平台同意那么商家走正常的退货流程，商家拒绝消费者不可退货；
              </p>
              <br /><br /><br /><br /><br />
            </div>
          </div>
          <div class="swiper-slide" v-if="isactive == 4">
            <div class="scbox">
              <p style="padding-top: 46px;font-size:30px">
                运费模板：运费模板工具用于批量设置商品的运费或运送服务，帮助商家提高效率;初始状态运费模板有两种：一种是非偏远包邮默认模板；另一种是包邮默认模板；系统默认非偏远包邮默认模板。
              </p>
            </div>
          </div>
          <div class="swiper-slide" v-if="isactive == 5">
            <div class="scbox">
              <h3>红包裂变</h3>
              <p>
                一款能快速裂变，让商家低成本增客的工具包，商家可以设置满减券、店铺爆款、店铺，设置一定的红包奖励，设置奖励条件，分享至微信、朋友圈、QQ、微博等，消费者达到商家设置的条件，即可获得红包奖励，通过红包、优惠券的激励，促使被分享者帮助自己快速传播裂变，达到店铺宣传、增加客流的效果。【温馨提示：如用户领取“优惠券（优惠券包含两种：满减券和代金券，满减券有使用限制，代金券无限制条件，以下统一用优惠券代替）“进行购物造成商家损失，商家自行承担】
              </p>
              <h3>客流互换</h3>
              <p>
                为商家打造的一个0成本的增客工具，商家满足活动要求即可参与互换活动，选择要互换的商品进入活动，系统会自动匹配合适的商家列表，选择互换商家开始活动，互换商品会在双方堂推店铺中显示，双方会员点击浏览、购买，达到任务要求后可以继续选择其他商家进行互换，让商家不再为高额的广告费而发愁，商家通过自身的100个会员与其他商家的100个会员交互共享，实现低成本裂变为200个客户，结束花钱买流量的时代
              </p>
              <h3>路过领券</h3>
              <p>
                设置商家真实线下店铺位置，设置优惠券，有消费者在商铺一公里范围内打开APP地图功能就会自动收到商家送的优惠券，增加购物需求。【温馨提示：如用户领取优惠券进行购物造成商家损失，商家自行承担】
              </p>
              <h3>会员折扣</h3>
              <p>
                关注店铺或者在店铺购物都会成为该店铺会员；商家有权对会员进行等级设置，等级越高享受会员折扣越高，会员可通过在店铺购物的累计消费额度提升等级。【温馨提示：如该会员有此店铺优惠券，会优先使用店铺会员折扣，如还满足优惠券使用的限制条件再进行优惠券的使用；注：如消费者进行折扣/优惠券购物商家出现损失，由商家自行承担】
              </p>
              <h3>指定发券</h3>
              <p>
                商家设置优惠券给指定的会员发送以此来增加消费者的购买欲，此优惠券只适用于会员内部使用【注：如消费者使用商家设置的指定优惠券消费，商家出现的损失由商家自行承担】
              </p>
              <h3>购物返券</h3>
              <p>
                商家设置此优惠券，消费者购买此商家的商品后同时会获得购物返还的优惠券，大大提高消费者的复购几率【注：如消费者使用商家设置的购物返券优惠券消费，商家出现的损失由商家自行承担】
              </p>

              <h3>免费领券</h3>
              <p>
                商家设置此优惠券后，优惠券会在堂推店铺中显示，消费者进店可以自行领取，购买商品时满足优惠券的条件即可直接享受优惠，增加消费者下单的几率，提高店铺营业额【注：如消费者使用商家设置的免费领券优惠券消费，商家出现的损失由商家自行承担】
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: [
        {
          test: "新手开店",
        },
        {
          test: "行业资质",
        },
        {
          test: "保证金",
        },
        {
          test: "商品",
        },
        {
          test: "物流",
        },
        {
          test: "工具箱",
        },
      ],
      isactive: 0,
    };
  },
  created() {},
  methods: {
    returns() {
      finish(); //返回上一层
    },

    onactive(id) {
      this.isactive = id;
    },
  },
};
</script>

<style>
@import "../assets/css/guideBook.css";
@import "../assets/css/common.css";
</style>