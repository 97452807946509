import App from './App.vue'
import router from './router'
import store from './store'
import Vue from 'vue'
import vueResource from 'vue-resource'
import less from 'less'

Vue.use(vueResource)
Vue.use(less)
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

Vue.config.productionTip = false
// 正式
Vue.prototype.USER = 'http://gyuser.guanyubuy.com'
Vue.prototype.STORE = 'http://gystore.guanyubuy.com'

// 测试
// Vue.prototype.USER = 'http://testgyuser.guanyubuy.com'
// Vue.prototype.STORE = 'http://testgystore.guanyubuy.com'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
