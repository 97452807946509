<template>
  <div id="problem">
    <div class="content">
      <div class="tfh">
        <a @click="returns()"><img src="../assets/img/jt1.png" alt="" /></a>
        常见问题
      </div>
      <ul class="list">
        <li
          v-for="(item, index) in list"
          :key="index"
          @click="clicklist(item.id, item.name)"
        >
          <span>{{ item.name }}</span>
          <img src="../assets/img/jt2.png" alt="" />
        </li>
      </ul>
      <!-- <div class="below-div">
            <label class="click-label">点击按钮联系官方客服</label>
        </div> -->
    </div>

    <!--    弹窗-->
    <div class="customer">
      <div class="urge">
        <div class="quan-coin">
          <label class="gou-coin">官方公众号</label>
          <img class="gou-img" src="../assets/img/cheng.png" alt="" />
        </div>
        <div style="padding: 30px">
          <img
            class="cu-img"
            src="../assets/img/public.png"
            id="copy-img"
            alt=""
          />
        </div>
        <textarea
          style="display: none"
          id="copycode"
          class="main-code"
          readonly="readonly"
        >
guanyumall</textarea
        >
        <div class="d3">
          <button class="know-btn">复制</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    var that = this;
    that.$http
      .get(that.USER + `/user/get_article_cat`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(
        (res) => {
          console.log(res.data.data);
          that.list = res.data.data;
        },
        (res) => {
          console.log("调用失败");
          elastic(res.data.msg);
        }
      );
  },
  methods: {
    returns() {
      finish(); //返回上一层
    },
    clicklist(id, name) {
      console.log(id);
      console.log(name);
      this.$router.push({
        path: "/wenzhang",
        query: {
          id,
          name,
        },
      });
    },
  },
};
</script>

<style>
@import "../assets/css/problem.css";
@import "../assets/css/common.css";
</style>