<template>
  <div id="wenzhang">
    <div class="content">
      <div class="fix">
        <div class="tfh">
          <a href="javascript:history.go(-1)">
            <img src="../assets/img/jt1.png" alt="" />
          </a>
          商家入驻
        </div>
      </div>
      <div class="splist">
        <ul class="inner">
          <li v-for="(item, index) in list" :key="index">
            <router-link
              :to="{ path: 'content', query: { article_id: item.id, fh: 1 } }"
            >
              <div class="d1 imgvc">
                <img :src="item.thumb" />
              </div>
              <div class="d2">
                <p class="p1 sheng2">{{ item.title }}</p>
                <p class="p2">时间：{{ item.created_at }}</p>
                <p class="p3">浏览次数:{{ item.click }}</p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      name: "",
      id: "",
      page: 1,
    };
  },
  created() {
    var that = this;
    that.id = that.$route.query.id;
    that.name = that.$route.query.name;
    that.$http
      .get(
        that.USER +
          `/user/get_article?article_cat_id=${that.id}&page=${that.page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(
        (res) => {
          console.log(res.data.data);
          that.list = res.data.data;
        },
        (res) => {
          console.log("调用失败");
          elastic(res.data.msg);
        }
      );
  },
  methods: {},
};
</script>

<style>
@import "../assets/css/wenzhang.css";
@import "../assets/css/common.css";
</style>