<template>
  <div id="share">
    <div class="tfh">
      <a @click="returns()"><img src="../assets/img/jt1.png" alt="" /></a>
      分享记录
    </div>
    <div class="top clear">
      <router-link
        :to="{ path: 'goodssharelist', query: { name: '商品分享' } }"
      >
        <div class="d1 div ying">
          <img src="../assets/img/shangpin.png" />
          <div>
            <p class="p1">商品分享</p>
            <p class="p2">记录分享商品的会员</p>
          </div>
        </div>
      </router-link>
      <router-link
        :to="{ path: 'storesharelist', query: { name: '店铺分享' } }"
      >
        <div class="d2 div ying">
          <img src="../assets/img/dianpu.png" />
          <div>
            <p class="p1">店铺分享</p>
            <p class="p2">记录分享店铺的会员</p>
          </div>
        </div>
      </router-link>
    </div>
    <div class="center ying">
      <div class="dt">
        <img src="../assets/img/phall.png" />
        商品分享排行
      </div>
      <ul id="goodsrank">
        <li v-for="(item, index) in goodslist" :key="index">
          <div class="dl">
            <div class="d1">
              <img :src="str[index]" alt="" srcset="" />
            </div>
            <span class="s1 sheng">{{ item.from.mobile }}</span>
            <span class="s2">{{ item.cr }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="center ying">
      <div class="dt">
        <img src="../assets/img/phall.png" />
        店铺分享排行
      </div>
      <ul id="storerank">
        <li v-for="(item, index) in storelist" :key="index">
          <div class="dl">
            <div class="d1">
              <img :src="strs[index]" alt="" srcset="" />
            </div>
            <span class="s1 sheng">{{ item.from.mobile }}</span>
            <span class="s2">{{ item.cr }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="loading" v-if="isload">
      <div>
        <img src="../assets/img/loadding.gif" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isload: false,
      goodslist: [],
      storelist: [],
      str: [],
      strs: []
    };
  },
  created() {
    localStorage.setItem(
      "token",
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2Mzc5OTQxMzksImV4cCI6MTY0MDU4NjEzOSwidHlwZSI6MSwiaWQiOjE5NX0.QxOh_Y7svNKFkjlcDVU2kjKxsOP4fOVEAXq-0p3iKhY"
    );
    this.goods_share_rank();
    this.store_share_rank();
  },
  methods: {
    goods_share_rank() {
      var _this = this;
      _this.$http
        .get(_this.STORE + "/goods/goods_share_rank", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(
          (res) => {
            console.log(res.data);
            _this.goodslist = res.data.data;
            for (const i in _this.goodslist) {
              _this.str.push(require("../assets/img/ph" + i + ".png"));
            }
            console.log(_this.str)
          },
          (res) => {
            console.log("调用失败");
          }
        );
    },
    store_share_rank() {
      var _this = this;
      _this.$http
        .get(_this.STORE + "/goods/store_share_rank", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(
          (res) => {
            console.log(res.data);
            _this.storelist = res.data.data;
            for (const i in _this.storelist) {
              _this.strs.push(require("../assets/img/ph" + i + ".png"));
            }
            console.log(_this.strs)
          },
          (res) => {
            console.log("调用失败");
          }
        );
    },
    returns() {
      finish(); //返回上一层
    },
  },
};
</script>

<style>
@import "../assets/css/share.css";
@import "../assets/css/common.css";
</style>